import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Circle, Marker, useMapEvents, useMap, Popup } from 'react-leaflet';
import L from 'leaflet';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import '../index.css';

const geocodingClient = mbxGeocoding({ accessToken: 'pk.eyJ1Ijoid29vdGVyIiwiYSI6ImNqNHBhdmU1NjI1ZzEycW83NGJ3ZHh2Z3QifQ.3ycP2LO9GRlXiMe7eTJ8EA' });

const ResetControl = ({ onReset }) => {
  return (
      <button
        id="reset-button"
        onClick={onReset}
        style={{ 
          position: 'absolute', 
          bottom: '50px', 
          left: '50%', 
          transform: 'translateX(-50%)',
          padding: '10px',
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          zIndex: 1000
        }}
      >
          Reset to Current Location
      </button>
  );
};

const MapComponent = () => {
    const [currentPosition, setCurrentPosition] = useState([51.505, -0.09]); // Default to London
    const [currentAddress, setCurrentAddress] = useState('');
    const [droppedPin, setDroppedPin] = useState(null);
    const [droppedPinAddress, setDroppedPinAddress] = useState(null);
    const watchId = useRef(null);
    const zoomLevel = window.innerWidth <= 760 ? 16 : 16;
    const [userMoved, setUserMoved] = useState(false);
    const [accuracy, setAccuracy] = useState(0);

    const MapUpdater = () => {
      const map = useMapEvents({
          dragstart: () => {
            setUserMoved(true);
          },
          click: (e) => {
            if (e.originalEvent.target.id !== 'reset-button') {
                setDroppedPin(e.latlng);
                geocodingClient.reverseGeocode({
                   query: [e.latlng.lng, e.latlng.lat],
                  limit: 1
                }).send().then(response => {
                  const match = response.body.features[0];
                  setDroppedPinAddress(match.place_name);
                });
            }
          },
      });
      
      useEffect(() => {
          if (!userMoved) {
            map.flyTo(currentPosition, zoomLevel, { duration: 1 });
          }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentPosition, userMoved]);

      return null;
    };

    const startGeolocationWatch = () => {
        if (navigator.geolocation) {
            watchId.current = navigator.geolocation.watchPosition(function(position) {
                const newCoords = [position.coords.latitude, position.coords.longitude];
                setCurrentPosition(newCoords);
                setAccuracy(position.coords.accuracy);
                geocodingClient.reverseGeocode({
                    query: [newCoords[1], newCoords[0]],
                    limit: 1
                }).send().then(response => {
                    const match = response.body.features[0];
                    setCurrentAddress(match.place_name);
                });
            }, function(error) {
                console.error("Error Code = " + error.code + " - " + error.message);
            }, { maximumAge: 0 });
        }
    };

    useEffect(() => {
        startGeolocationWatch();
        const setVh = () => {
          const vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
  
        setVh(); // Set the initial value
  
        window.addEventListener('resize', setVh);

        return () => {
            if (watchId.current) {
                navigator.geolocation.clearWatch(watchId.current);
            }
            window.removeEventListener('resize', setVh);
        };
    }, []);

    const droppedPinIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    const ResetButton = () => {
      const map = useMap();
  
      const resetToCurrentLocation = () => {
        setUserMoved(false);
        map.flyTo(currentPosition, zoomLevel, 1);
      };
  
      return !userMoved ? null : <ResetControl onReset={resetToCurrentLocation} />;
    };

    return (
        <div style={{
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'space-between',  
          height: 'calc(var(--vh, 1vh) * 100)', 
          background: 'linear-gradient(to bottom, #9C0017, #18448C)'
        }}>
            <h1 style={{fontSize: '20px', color: 'white'}}>Current Address: {currentAddress}</h1>
            <MapContainer center={currentPosition} zoom={zoomLevel} style={{ flex: 1, width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Circle center={currentPosition} radius={accuracy} /> {/* adjust radius as needed */}

                {droppedPin && (
                  <Marker 
                    position={droppedPin} 
                    icon={droppedPinIcon}
                    key={droppedPin.toString()}
                    eventHandlers={{ 
                      add: (e) => {
                        e.target.openPopup();
                      }
                    }}
                  >
                    <Popup>{droppedPinAddress}</Popup>
                  </Marker>
                )}
                <MapUpdater />
              <ResetButton />
            </MapContainer>
            
            {droppedPinAddress && (
              <div style={{ padding: '10px', color: 'white' }}>
                <h2>Selected Address: {droppedPinAddress}</h2>
              </div>
            )}
            <div className="icon-container" style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', maxHeight: '25vh', padding: '0 10px'}}>
              <a href="tel:101"><img className="emergency-icon" src="icon_police.svg" alt="Police" style={{flex: 1, objectFit: 'contain', maxHeight: '100%'}} /></a>
              <a href="tel:112"><img className="emergency-icon" src="icon_fire.svg" alt="Fire" style={{flex: 1, objectFit: 'contain', maxHeight: '100%'}} /></a>
              <a href="tel:112"><img className="emergency-icon" src="icon_medical.svg" alt="Medical" style={{flex: 1, objectFit: 'contain', maxHeight: '100%'}} /></a>
              <a href="tel:"><img className="emergency-icon" src="icon_tow.svg" alt="Towing" style={{flex: 1, objectFit: 'contain', maxHeight: '100%'}} /></a>
            </div>
          </div>
    );
};

export default MapComponent;